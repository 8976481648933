#dogs {
  width: 100%;
  background-color: #9ed7b7; 

  a {
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #31e205;
      font-weight: bold;
    }
  }
}

.dogs-content {
  height: 100%;
  margin: auto;
  background-color: #b6e1ca;

  .container {
    display: block;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }

  .dog-card {
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    text-align: center;

    .dog-name {
      margin-top: 0.5rem;
    }
  }

  .top-img {
    width: 100%;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}

.facts {
  padding: 3rem 1.5rem;
  text-align: left;

  ul {
    list-style: none;
    padding-bottom: 1rem;

    li {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
}

.dogs-gallery {
  padding: 1rem;
  width: 100%;

  h2 {
    text-align: center;
  }
  .dogs-images {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;

    div {
      width: 100%;
    }

    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .dogs-content {
    .container {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }

    .dog-card {
      width: 100%;
      padding: 1rem;

      .top-img {
        width: 100%;
      }
    }

    .top-img{
      width: 55%;
    }

  }

  .facts {
    width: 45%;
  }

  .dogs-gallery {
    padding: 2rem 0;

    .dogs-images {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (min-width: 992px) {
  .dogs-content {
    .container {
      padding: 2rem 0;
    }

    .dog-card {
      width: 100%;
      padding: 1rem;

      .top-img {
        width: 100%;
      }
    }

    .top-img{
      width: 50%;
    }

  }
  
  .facts {
    width: 45%;
    padding: 3rem 0;
  }
  
  .dogs-gallery {
    .dogs-images {
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
    }
  }
}