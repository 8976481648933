#navbar {
  background-color: #9ed7b7;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
}

.navbar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    font-family: 'Special Elite', cursive;
    font-size: 1.6rem;
    margin-left: 0;
  }

  ul {
    list-style: none;
    display: flex;
    font-size: 1.2rem;
    font-weight: 700;

    li {
      margin-right: 1.5rem;
    }
  }

  a {
    text-decoration: none;
  }
}

// Navbar toggler
.collapse {
  display: none;
}

.toggle-navbar-menu {
  display: none;
}

.navbar-toggler {
  font-size: 1.6rem;
  height: 1.6rem;
  width: 1.6rem;
  background: transparent;
  border: 1px solid transparent;
  position: relative;
  transition: ease-in-out 0.8s;
  cursor: pointer;

  i {
    height: 1.6rem;
    width: 1.6rem;
  }

  .closed {
    .icon-left {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotateY(0deg);
      transition: ease-in-out 0.5s;
    }

    .toggle-navbar-menu {
      display: block;
      position: absolute;
      right: -30rem;
      top: 2.5rem;
      border-radius: 8px 0 0 8px;
      padding: 0;
      width: 350px;
      transform: scaleX(0);
      transition: ease-in-out 0.5s;
    }

    .icon-right {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotateY(180deg);
      transition: ease-in-out 0.5s;
      color: transparent;
    }
  }

  .open {
    .icon-left {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotateY(180deg);
      transition: ease-in-out 0.5s;
      color: transparent;
    }

    .icon-right {
      position: absolute;
      top: 0;
      right: 0;
      transform: rotateY(0deg);
      transition: ease-in-out 0.5s;
      color: black;
    }

    .toggle-navbar-menu {
      display: block;
      position: absolute;
      right: -3.5rem;
      top: 2.5rem;
      border-radius: 8px 0 0 8px;
      padding: 0;
      width: 350px;
      transform: scaleX(1);
      transition: ease-in-out 0.5s;
      background-color: #9ed7b7;
    }
  }
}

@media only screen and (min-width: 600px) {
  .navbar-toggler {
    .open {
      .toggle-navbar {
        right: -4rem;
      }
    }
  }
  
}

@media only screen and (min-width: 768px) {
  #navbar {
    padding: 2rem;
  }
  
  .navbar-toggler {
    font-size: 2rem;
    height: 2rem;
    width: 2rem;

    i {
      height: 2rem;
      width: 2rem;
    }

    .open {
      .toggle-navbar-menu {
        right: -5rem;
        padding: 0 0 1rem 0;
      }
    }

    .closed {
      .toggle-navbar-menu {
        padding: 0 0 1rem 0;
      }
    }
  }

  .navbar-content {
    .logo {
      font-size: 2rem;
    }

    a:hover {
      color: #c528bc;
    }

    ul {
      font-size: 1.5rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  .navbar-content {
    width: 80%;
    max-width: 1400px;
  
    .logo {
      margin-left: 2rem;
    }
  }
}

@media only screen and (min-width: 1100px) {
  // Navbar toggler
  .navbar-toggler {
    display: none;
  }

  .collapse {
    display: flex;
  }
}