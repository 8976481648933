#footer {
  width: 100%;
  background-color: #171614;
  color: #e7d9e0;
  margin-top: auto;

  section {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
  }

  div {
    width: 50%;
  }

  ul {
    width: 50%;
    list-style: none;
    font-weight: 700;
    display: block;
    text-align: center;
  }

  li {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  li:last-of-type {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: #d7cdcc;

    &:hover {
      color: #3caf6f;
    }
  }

  .footer-left {
    .logo-footer {
      font-family: 'Special Elite', cursive;
      font-size: 2rem;
      font-weight: 500;
    }

    .social {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        font-size: 1.8rem;
        margin-right: 1.5rem;
        margin-top: 0.5rem;
      }

      i {
        color: #d7cdcc;

        &:hover {
          color: #3caf6f;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #footer {
    .footer-left {
      display: flex;
      align-items: center;
      width: 60%;

      .logo-footer {
        font-size: 2rem;
        margin-right: 0.5rem;
      }

      .social {
        justify-content: space-evenly;

        a {
          margin-top: 0;
          margin-right: 1rem;
        }
      }
    }
    
    ul {
      width: 40%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
    }

    li {
      font-size: 1.4rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  #footer {
    section {
      width: 80%;
      padding: 3rem;
    }

    ul {
      display: flex;
      justify-content: space-between;
    }

    li {
      font-size: 1.2rem;
    }
  }
}