#about {
  width: 100%;
  background-color: #9ed7b7;
}

.about-content {
  background-color: #ceebdc; 
  height: 100%;
  width: 100%;
  margin: auto;
  padding: 1rem;

  .container {
    display: block;
    align-items: center;

    .top-img {
      width: 100%;

      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .about-content-text {
      width: 100%;
      margin-top: 1rem;

      p {
        margin-bottom: 1rem;
      }
    }
  }
}


@media only screen and (min-width: 768px) {
  .about-content {

    .container {
      padding: 1rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-img {
        width: 50%;
      }

      .about-content-text {
        width: 50%;
        margin-left: 1rem;
      }
    }  
  }
}

@media only screen and (min-width: 992px) {
  .about-content {
    .container {
      .top-img {
        width: 35%;
    
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
    
      .about-content-text {
        width: 60%;
    
        p {
          margin-bottom: 1rem;
        }
      }
    }
  }
}