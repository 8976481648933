.notfound {
  background-color: #ceebdc;

  .container {
    padding: 1rem;
  }

  ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem 0;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    font-size: 1.5rem;

    &:hover {
      color: #31e205;
    }
  }
}

@media only screen and (min-width: 768px) {
  .notfound {
    ul {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media only screen and (min-width: 992px) {
  .notfound {
    height: 50vh;
    
    ul {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }
}