#puppies {
  width: 100%;
  background-color: #9ed7b7;
}

.puppies-content {
  background-color: #ceebdc; 

  .container {
    padding: 2rem 1rem;
    display: block;
    justify-content: center;
    align-items: center;
  }
  

  .top-img {
    width: 100%;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  .puppies-text {
    padding: 1rem 0;
  }

  h2 {
    margin-top: 0.5rem;
  }
  
  a {
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #31e205;
      font-weight: bold;
    }
  }

  p {
    margin-bottom: 1rem;
  }
}

.puppies-img-one {
  width: 100%;

  .img-container {
    width: 100%;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}

.puppies-img-many {

  .img-container {
    width: 100%;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}

.puppies-img-y-many {

  .img-container {
    width: 100%;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }
}

.puppies-img-gallery {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  .img-container {
    width: 100%;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  .portrait {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  #puppies {
    .puppies-content:nth-child(odd) {
      background-color: #b6e1ca;
      .container {
        flex-direction: row-reverse;
      }

    }
  }

  .puppies-content {
    .container {
      padding: 1rem 0.5rem;
      display: flex;
      justify-content: space-between;
    }

    .top-img {
      width: 52%;
    }

    .puppies-text {
      width: 45%;
    }
  }

  .puppies-img-one {
    padding: 0 2rem;
  
    .img-container {
      width: 90%;
  
      img {
        width: 100%;
      }
    }
  }

  .puppies-img-many {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;

    .img-container {
      width: 100%;
  
      img {
        width: 100%;
        border-radius: 8px;
      }
    }
  }

  .puppies-img-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  
    .img-container {
      width: 100%;
  
      img {
        width: 100%;
        border-radius: 8px;
      }
    }

    .portrait {
      display: block;
    }

    .landscape {
      display: none;
    }
  }
}

@media only screen and (min-width: 992px) {
  .puppies-content {
    .container {
      padding: 2rem 3rem;
    }
  }
}