#connect {
  background-color: #9ed7b7;
}

.connect-content {
  background-color: #ceebdc;

  .container {
    display: block;
    align-items: center;
    padding: 2rem;
  }

  .connect-side {
    width: 100%;
  }

  img {
    width: 100%;
    max-width: 750px;
    border-radius: 8px;
  }

  h2 {
    margin-top: 1rem;
  }

  h3 {
    margin: 2rem 0;
    font-size: 1.5rem;
    color: #510b0b;
  }

  a {
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #31e205;
      font-weight: bold;
    }
  }

  p {
    margin-bottom: 0.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .connect-content {
    .container {
      display: flex;
      padding: 2rem 1rem;
    }

    .connect-side {
      width: 50%;
      padding: 1rem;
    }
  }
}

@media only screen and (min-width: 992px) {
  .connect-content {
    .container {
      padding: 2rem;
    }
  }
}