@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

@import './home';
@import './navbar';
@import './footer';
@import './puppies';
@import './about';
@import './connect';
@import './dogs';
@import './notfound';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #153126;
}

html, body {
  background-color: #9ed7b7;
  color: #f0fff9;
  font-family: 'Oxygen', sans-serif;
}

h1 {
  font-family:  'Special Elite', cursive;
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

h2 {
  font-family:  'Special Elite', cursive;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

#App {
  height: 100vh;
}

// For all pages
.page-top {
  padding: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
  
  p,
  a {
    font-family: 'Oxygen', sans-serif;
    font-size: 1.5rem;
  }

  a {
    text-decoration: none;

    &:hover {
      color: #02723a;
    }
  }
}

.container {
  width: 100%;
  margin: auto;
}

.banner {
  background-color: white;
  width: 100%;

  a {
    padding: 2rem;
  }

  div {
    width: 90%;
    max-width: 880px;
    margin: auto;

    img {
      width: 100%;
    }
  }
}


@media only screen and (min-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  .page-top {
    padding: 3rem;
  }

  .container {
    width: 90%;
  }

  .banner {
    div {
      width: 80%;
    }
  }
}

@media only screen and (min-width: 992px) {
  h1 {
    font-size: 3rem;
  }
  
  h2 {
    font-size: 2.2rem;
  }
  
  .page-top {
    padding: 3rem 5rem;
    margin-bottom: 2rem;
  }

  .container {
    width: 80%;
    max-width: 1400px;
  }
  
  .banner {
    background-color: white;
    width: 100%;

    div {
      width: 60%;
    }
  }
}