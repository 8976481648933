#home {
  width: 100%;
  background-color: #9ed7b7;
}

.home-content {
  height: 100%;
  margin: auto;
  background-color: #ceebdc; 
}

.card:nth-child(even) {
  background-color: #b6e1ca;
}

.card {
  margin: auto;

  a {
    text-decoration: none;
    color: #f0fff9;

    &:hover {
      opacity: 0.8;
    }
  }

  .section-link {
    display: block;
    align-items: center;
  }

  .card-img-container {
    width: 80%;
    margin: auto;
    padding-top: 0.5rem;

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  .section-text {
    padding: 1rem 2rem;

    h2 {
      text-align: center;
    }

    h3 {
      margin: 2rem 0;
      color: #370701;
      font-size: 1.3rem;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  .puppy-border {
    padding: 0 2rem;
    border: 2px solid #370701;
    margin-bottom: 1rem;
  }
}


@media only screen and (min-width: 768px) {
  .section-link {
    display: flex;
    align-items: center;
  }

  .card:nth-child(even) {
    .section-link {
      flex-direction: row-reverse;
    }
  }
}

@media only screen and (min-width: 992px) {
  .card {
    .section-link {
      width: 80%;
      margin: auto;
      display: flex;
      align-items: center;
    }

    .card-img-container {
      width: 50%;
      max-width: 750px;
      margin: 0.5rem;
    }
  
    .section-text {
      width: 40%;
      padding: 0;
      padding-left: 1rem;
      
      h2 {
        text-align: left;
      }

      h3 {
        font-size: 1.4rem;
      }
    }
  }
}